
* {
  font-family: "Mukta", sans-serif !important;
  font-family: "Noto Sans", sans-serif !important;
  font-family: "Poppins", sans-serif !important;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.error-boundary {
  display: flex;
  flex-direction: column;
  width: 100vw;
  justify-content: center;
  height: 100vh;
  align-items: center;
}
.error-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.e404 {
  color: red;
  font-weight: 600;
}

.go-back {
  width: 130px;
  margin-top: 4px;
  background-color: rgb(228, 174, 174);
  height: 30px;
  color: #000;
  font-weight: 600;
  font-size: small;
  cursor: pointer;
  border-radius: 5px;
  border: none;
}

/* close 404 page */

/* horizontal line */
.line-hori {
  width: 40%;
  margin-top: 3%;
  color: red;
  height: 2px;
  background-color: red;
  border: none;
  border-radius: 10%;
}
/* horizontal line close */

/*  header here */
.header-top {
  width: 100vw;
  height: 6rem;
  position: fixed;
  z-index: 9;
  /* background-color: #404040; */
}
.header-body {
  display: flex;
  justify-items: center;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px; */
  background-image: url("./src/components/assets/images/background-brand.jpg");
  /* background-color: #3C3C3C; */
  height: 100px;
  width: 100vw;
  background-repeat: round;
  /* -webkit-filter: blur(1px); */
  height: 110px;
  /* box-shadow: rgb(38, 57, 77) 0px 20px 30px -16px; */
}
.brandlogo {
  width: 200px;
  padding: 10px;
  position: relative;
  top: 0;

  /* height: 30px; */
}

.login {
  /* padding: 10px; */
}
.login-btn {
  /* background-color: transparent; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: 600;
  font-size: large;
  width: auto;
}

.link-name {
  width: 10vw;
  font-weight: 600;
  display: flex;
  justify-content: space-evenly;
  /* background-color: black; */
}
.ant-dropdown-menu-title-content {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* --------------end--------------- */

/* Login Page  */

.login-page {
  display: flex;
  justify-content: center;
  /* height: calc(100vh - 6rem); */
  height: 70vh;
  /* background-color: rgb(238, 220, 187); */
  /* background-image: url("/src/components/assets/images/loginback.jpg"); */
  /* margin-top: -30px; */
}
.login-tag {
  font-weight: 600;
  color: black !important;
}
.login-container {
  width: 80%;
  display: flex;
  border-radius: 10px;
  margin-top: 200px;
}
.login-page-left {
  /* background-color: rgb(88, 75, 75); */
  width: 100vw;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-page-right {
  background-color: rgb(75, 66, 66);
  width: 20%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.form {
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

/* custom form */

.link-tag {
  color: #680000;
}

.wrapper {
  width: 320px;
  min-height: 60vh;
  /* height: 60vh; */
  background-color: #f8f5f5;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
  padding: 30px;
  /* border: 1px solid black; */
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  align-items: center;
}

.input-field {
  width: 94%;
  height: -8px;
  border: none;
  padding: 10px;
  background-color: #eeeeee;
  color: black;
  outline: none;
  font-size: 15px;
  margin-bottom: 20px;
  transition: 0.5s;
  border-radius: 5px;
  font-weight: bold;
  letter-spacing: 3.5px;
}

input:hover {
}

.heading {
  color: #680000;
  margin-bottom: 20px;
  display: flex;
  /* justify-content: space-between; */
}
.loginlogo {
  width: 50px;
  padding: 20px;
}
.heading p {
  color: #aaa8bb;
}

.heading i {
  font-size: 30px;
  color: #4d61fc;
}

.custom-checkbox {
  color: #aaa8bb;
  font-weight: 400;
}

button {
  width: 200px;
  background-color: #680000;
  color: white;
  border: none;
  height: 34px;
  font-weight: bold;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-bottom: 20px;
}

button:hover {
  background-color: #524d4d;
}

.custom-checkbox {
  width: 17px;
  height: 17px;
  border-radius: 5px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  margin-right: 5px;
}

input[type="checkbox"]:checked ~ .custom-checkbox {
  background-color: #31285c;
}

input[type="checkbox"]:checked ~ .custom-checkbox::before {
  font-family: "Font Awesome 5 Free";
  content: "\f00c";
  display: inline-block;
  font-weight: 900;
  color: #ffffff;
}

.wrapper-right {
  width: 200px;
  /* height: 80vh; */
  /* margin-top: -6px; */
  margin-left: 60px;
}
.long-logo {
  width: 100%;
  height: 65vh;
  margin-top: -27px;
  /* min-height: 65vh; */
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
@media only screen and (max-width: 800px) {
  .long-logo {
    display: none;
  }
  .wrapper-right {
    display: none;
  }
}
@media only screen and (min-width: 800px) {
  .loginlogo {
    display: none;
  }
}

/*  sidebar */

nav {
  position: fixed;
  width: 60px;
  height: 100%;
  background: var(--bg-secondary);
  transition: 0.5s;
  overflow: hidden;
  margin-top: 14px;
  z-index: 9999;
}

nav.active {
  width: 239px;
}

/* nav:hover {
  width: 300px;
} */

nav ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

nav ul li {
  list-style: none;
  width: 100%;
  position: relative;
  opacity: 1;
}

nav ul li a:hover {
  /* color: var(--bg-primary); */
  cursor: pointer;
}

nav ul li:hover a::before {
  /* background-color: var(--bg-active); */
  background-color: white;

  width: 100%;
  opacity: 1;
}

nav ul li:hover a {
  color: black;
  font-weight: 600;
}
nav ul:hover {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

nav ul li a {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--cl-text);
  transition: 0.2s;
}

nav ul li a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Change this to 100% to make icons always visible */
  height: 100%;
  transition: 0.2s;
}

nav ul li a .icon {
  position: relative;
  display: block;
  min-width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  opacity: 1;
}

nav ul li a .title {
  position: relative;
  font-size: 0.85em;
  opacity: 1;
}

nav ul li a .icon * {
  font-size: 1.1em;
}

nav ul li a.toggle-button {
  border-bottom: 3px solid var(--bg-primary);
}

.toggle-button {
  cursor: pointer;
}

header {
  display: none;
}

@media (max-width: 500px) {
  header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 60px;
    background: var(--bg-secondary);
    color: var(--cl-text);
  }

  header a {
    color: var(--cl-text);
  }

  nav {
    left: -60px;
  }

  nav.active {
    left: 0;
    width: 100%;
  }

  nav ul li a.toggle-button {
    display: none;
  }
}

:root {
  --bg-primary: #41444b;
  --bg-secondary: #3c3c3c;
  --bg-active: #b87d79;
  --cl-text: #fff;
}

nav ul li a .icon,
nav ul li a .title {
  opacity: 1; /* Set opacity to 1 to make icons and titles always visible */
}

.toggle-bottom {
  bottom: 0;
}
.title {
  font-weight: 600;
  font-size: 35px;
  letter-spacing: 1px;
}

/*  add user */
.wrapper-adduser {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
@media only screen and (max-width: 601px) {
  .wrapper-adduser .flex-container {
    display: flex;
    flex-direction: column;
  }
  .wrapper-adduser .flex-container .ant-select-selector {
    width: 400px;
  }
}
.flex-container {
  display: flex;
  justify-content: space-between;
  /* padding: 10px; */
}

.wrapper-adduser-in {
  width: 700px;
  min-height: 50vh;
  background-color: #f8f5f5;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
  padding: 30px;
  /* border: 1px solid black; */
  border-radius: 10px;
  align-items: center;
  margin-top: 56%;
}

/* Add Users  */

.input-grp {
  width: 50%;
}
.user-ipt {
  width: 41%;
}
.user-ipt-extra {
  width: 100%;
}

/* student page */

.container-student {
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.container {
  /* width: 100vw;
  height: 90vh;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll; */
}

/* @media (min-width: 400px) {
  .container {
    margin-top: 10%;
  }
} */
.book-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 70vw;
  /* background-color: #f8f5f5; */
}
.container-form {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
  /* width: 80%; */
  align-items: center;
}
.heading {
  display: flex;
  justify-content: space-between;
}
Form.Item {
  font-weight: 600;
}
.container-slot {
  background-color: #f8f5f5;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* table  */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  max-height: 500px;
  overflow: scroll;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

@media screen and (max-width: 600px) {
  th,
  td {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  th {
    text-align: left;
  }
}

.contain-table {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* margin-top: 10%; */
}

li.highlighted {
  background-color: #e6f7ff; /* Change the background color to your preference */
}

.dropdown-sidebar {
  /* margin-top: 10px; Adjust the top margin to move the dropdown down */
  background-color: red;
}
.table-list {
  margin-top: 10%;
}
.wrapper-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  /* height: 100vh; */
  flex-direction: column;
  margin-left: 130px;
  margin-top: 42px;
}
.example {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: rgba(0, 0, 0, 0.05); */
  border-radius: 4px;
  width: 100vw;
  height: 100vh;
}

/* .input-field-ant {
  width: 300px;
} */

/* loading  */

.i-loader-inf-horizontal-container {
  width: 256px;
  text-align: center;
}

.i-loader-inf-horizontal-container span {
  color: #475161;
  font-family: sans-serif;
  animation: loader-inf-message-pulse 2s infinite;
}

.i-loader-inf-horizontal {
  width: 100%;
  height: 18px;
  background: linear-gradient(#5399ee 0 0) 0/0% no-repeat#ddd;
  border-radius: 4px;
  animation: loader-inf 2s infinite linear;
}

@keyframes loader-inf {
  100% {
    background-size: 100%;
  }
}

@keyframes loader-inf-message-pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.pt-8px {
  padding-top: 8px;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-inline-start: 0px;
}
.ant-modal .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-inline-start: 0px;
}
.ant-drawer .ant-drawer-close {
  width: 20px;
}
/* reset password  */
.reset {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.input-field-reset {
  width: 380px;
  height: -1px;
  border: none;
  padding: 10px;
  background-color: #eeeeee;
  color: black;
  outline: none;
  font-size: 15px;
  margin-bottom: 20px;
  transition: 0.5s;
  border-radius: 5px;
  font-weight: bold;
  letter-spacing: 3.5px;
}
.reset-form {
  margin-bottom: 40px;
  width: 400px;
}
.password-input {
  display: flex;
  /* align-items: center; */
}
.character-count {
  margin-top: 6px;
  padding: 5px;
  color: red;
  /* font-size: 16px; */
}
.password-toggle-icon {
  margin-top: 9px;
  /* padding: 5px; */
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn,
.ant-modal .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-top: 21px;
}
.enroll-list-student {
  margin-top: 124px;
}
/* Add this CSS to your component or stylesheet */
.userEmailList {
  list-style: none;
  padding: 0;
  max-height: 150px; /* Set the desired max height for the list */
  overflow-y: auto; /* Add a scrollbar when the list exceeds the max height */
  border: 1px solid #e8e8e8; /* Add a border for better visibility */
  border-radius: 4px;
  margin-bottom: 10px;
}

.userEmailList li {
  padding: 8px;
  border-bottom: 1px solid #e8e8e8;
}

.userEmailList li:last-child {
  border-bottom: none; /* Remove border for the last item to avoid double borders */
}

.remove-email {
  display: flex;
  justify-content: end;
}
.flex-container-row {
  /* width: 422px; */
  display: flex;
  margin-top: 30px;
  float: right;
  justify-content: center;
  align-items: center;
  height: 30%;
}
.search-field {
  width: 350px;
  margin-right: 10px;
  font-weight: bold;
}

.flex-container-rowBox {
  display: flex;
  /* margin-top: 30px; */
  float: right;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}
.ant-table-cell {
  font-weight: bold;
  font-size: 14px;
}
.search-field .ant-input::placeholder {
  /* font-weight: bold; */
  color: black;
}
.search-field .ant-input {
  border: 1px solid #680000;
}
.ant-input-search-button:not(.ant-btn-primary) {
  border: 1px solid #680000;
}
.ant-pagination-total-text {
  font-weight: bold;
  font-size: 14px;
}

/* accordian  */

.accordian {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: orange; */
  width: 100vw;
  /* height: 100vh; */
  flex-direction: column;
  position: absolute;
  top: 23%;
}
.accordian-icon {
  display: flex;
  justify-content: space-between;
  width: 50px;
}
.accordian-header {
  display: flex;
  justify-content: space-between;
  width: 50vw;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #e6e6e6;
  height: 50px;
  /* height: 20px; */
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
}
.role-head {
  font-weight: bold;
}
.example2 {
  display: flex;
  justify-content: space-between;
  width: 50vw;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #e6e6e6;
  height: 50px;
  /* height: 20px; */
  align-items: center;
  margin-bottom: 20px;
  /* position: absolute; */
}

.timer {
  position: absolute;
  top: 17%;
  width: 100vw;
  display: flex;
  justify-content: end;
  /* padding: 10px; */
  float: right;
}

.question-mark-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 999;
}

.question-mark {
  width: 40px;
  height: 40px;
  background-color: #3498db;
  color: #fff;
  font-size: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.contact-info {
  position: absolute;
  bottom: 50px;
  right: -150px;
  background-color: #3498db;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  display: none;
  margin-right: 160px;
}

.question-mark-container.hovered .question-mark {
  background-color: #2980b9;
}

.question-mark-container.hovered .contact-info {
  display: block;
}

.home-wrap {
  display: flex;
  flex-direction: column;
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 910px) {
  .home-wrap {
    display: flex;
    flex-direction: column;
    /* height: 100vh; */
    justify-content: center;
    align-items: center;
  }
  .wrapper-box {
  }
}

/* country code  */

.custom-dropdown {
  position: relative;
}

.custom-dropdown select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 8px 30px 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  background-size: 20px; /* Adjust the size of the flag */
  background-repeat: no-repeat;
  background-position: 95% center;
}

.custom-dropdown select option {
  padding: 10px;
}

.iti {
  display: inline-block;
  position: relative;
  width: 369px;
}

.iti__selected-dial-code {
  font-weight: bold;
}

.loginlogo {
  animation: infinite 20s linear;
}

.frame {
  width: 90%;
  margin: 40px auto;
  text-align: center;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.custom-btn {
  width: 300px;
  height: 40px;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
}

/* 1 */
.btn-1 {
  background: rgb(6, 14, 131);
  background: linear-gradient(
    0deg,
    rgba(6, 14, 131, 1) 0%,
    rgba(12, 25, 180, 1) 100%
  );
  border: none;
}
.btn-1:hover {
  background: rgb(0, 3, 255);
  background: linear-gradient(
    0deg,
    rgba(0, 3, 255, 1) 0%,
    rgba(2, 126, 251, 1) 100%
  );
}

.wrapper-create-slot {
  width: 990px;
  /* min-height: 50vh; */
  background-color: #f8f5f5;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
  padding: 30px;
  border-radius: 10px;
  align-items: center;
  margin-top: 15%;
  height: 70vh;
  overflow: scroll;
}

/* styles.css */
.date-time-picker {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.label {
  display: block;
  margin-bottom: 5px;
}

.date-picker,
.time-picker {
  margin-bottom: 15px;
}

.table-row {
  display: flex;
  align-items: center;
}

.table-row td {
  flex: 1;
}

.calendar-text {
  margin-left: 5px; /* Adjust spacing as needed */
  font-size: 18px; /* Adjust font size as needed */
  /* Add any additional styling properties */
}
.date-time-container {
  display: flex;
  align-items: center;
}

.date-picker-container {
  margin-right: 10px; /* Adjust spacing between date and time pickers */
}

.time-picker-container {
  margin-right: 10px; /* Adjust spacing between time pickers */
}

.calendar-text {
  margin-left: 5px; /* Adjust spacing as needed */
  font-size: 18px; /* Adjust font size as needed */
  /* Add any additional styling properties */
}

.time-selection-container {
  display: flex;
  align-items: center;
}

.time-picker-container {
  margin-right: 10px; /* Adjust spacing as needed */
}

.time-dropdowns {
  display: flex;
  align-items: center;
}

.react-time-picker__wrapper {
  display: flex;
  align-items: center;
}

.react-time-picker__inputGroup {
  margin-right: 5px; /* Adjust spacing between time dropdowns */
}

.slot-container {
  display: flex;
  align-items: center;
}

.slot-inputs {
  margin-right: 10px; /* Adjust spacing between inputs and buttons */
}

.slot-buttons button {
  margin-right: 5px; /* Adjust spacing between buttons */
}

.green-background {
  background-color: rgb(180, 212, 180);
}

.ant-popover-title {
  color: white !important;
}
.slot-button {
  width: 67px;
  height: 31px;
  margin-left: 10px;
}

.shimmer {
  padding: 5px;
  width: 95%;
  height: 120px;
  margin: 10px auto;
  background: #ffffff;
}
.shimmer .image-card {
  height: 90px;
  width: 90px;
  float: right;
  border-radius: 8px;
}
.stroke {
  height: 15px;
  background: #777;
  margin-top: 20px;
}

.wrapper {
  width: 0px;
  animation: fullView 0.5s forwards linear;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}
.animate {
  animation: shimmer 3s;
  animation-iteration-count: infinite;
  background: linear-gradient(to right, #e6e6e6 5%, #cccccc 25%, #e6e6e6 35%);
  background-size: 1000px 100%;
}
@keyframes shimmer {
  from {
    background-position: -1000px 0;
  }
  to {
    background-position: 1000px 0;
  }
}

.no-data {
  width: 50vw;
  border: 1px solid grey;
  justify-content: center;
  display: flex;
  border-radius: 7px;
  margin-top: 30px;
}

.section1 {
  background: linear-gradient(to right, #e9e9f0, #e9effa, #e3ebec);
  padding: 30px;
  border-radius: 10px;
}
/* bulk upload  */

.bulkupload {
  display: flex;
  justify-content: center;
  flex-direction: column;

  width: 100vw;

  align-items: center;
  height: 100vh;
}
.bulkupload-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
}

.short-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  width: 100vw;
}
.cards-row {
  display: flex;
  justify-content: space-between;
  width: 80vw;
  height: 200px;
  background-color: #d9e8c0;
  align-items: center;
  padding: 30px;
  margin-bottom: 60px;
  border-radius: 20px;
}
.card-img {
  width: 100px;
}
.wrapper-box-homepage {
  display: flex;
  justify-content: center;
}

/* ndcksd */
.fileUpload {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.bar404 {
  width: 100vw;
  height: 37%;
  background-image: url("https://img.freepik.com/premium-vector/error-404-page-found-natural-concept-illustration-background-web-missing-landing-page_607751-174.jpg?w=740");
  background-repeat: no-repeat;
  background-position: top;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-modal-confirm-btns {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}
.ant-btn css-dev-only-do-not-override-qgg3xn ant-btn-default {
  /* margin-bottom: 0px !important; */
}

.ant-notification-notice-message {
  font-weight: bold;
}

/* Add user page css */
.add-user-page {
  /* background-color: #F8F5F5; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  flex-wrap: wrap;
  border-radius: 30px;
}
.add-user-left {
  width: 600px;
  height: 50%;
  background-color: #f8f5f5;
  padding: 20px;
  margin-top: 100px;
  margin-left: 50px;
}

@media only screen and (max-width: 601px) {
  .add-user-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }
  .add-user-left .flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .add-user-left .flex-container .input- {
    width: 400px;
  }

  .flex-container-select {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .add-user-left .flex-container .input- .input-field-ant {
    width: 400px;
  }
}

.add-user-right {
  margin-top: 100px;
  width: 201px;
  height: 50%;
  background-color: #680000;
  /* background-image: url("/src//components//assets//images/bulk.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */

  padding: 20px;
  /* border-radius: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  align-items: center !important;
}
.add-user-left .flex-container {
  justify-content: space-around;
  margin-left: -25px;
  display: flex;
}
.flex-container-select {
  display: flex;
  justify-content: space-between;
}
.add-user-left .flex-container .input- .input-field-ant {
  width: 169px !important;
}
/* admin dashboard  */

.admin-dash {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98vw;
  /* background-color: rgb(235, 225, 225); */
  padding: 14px;
  /* height: 400px; */
  /* height: 30vh; */
  /* margin-top: 50px; */
  overflow-x: hidden;
}
.admin-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-around;
  margin-left: 40px;
  width: 81vw;
  align-items: center;
  flex-shrink: 3;
  margin-top: 110px;
}
.student-count {
  width: 210px;
  border: 1px solid rgb(233, 233, 233);
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 20px;
  border-radius: 10px;
}
.user-count {
  width: 250px;

  height: 80px;
  display: flex;

  align-items: center;
  border-radius: 10px;
  flex-direction: column;

  /* background: linear-gradient(to right, #dbebdf, #ffffff, #ffd7ca); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.usercard1 {
  display: flex;
  justify-content: flex-end;
  color: rgb(255, 255, 255);
  background-color: #e55353;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.usercard2 {
  /* background-color: #3399ff; */
  background-color: #28a0f1;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.usercard3 {
  background-color: rgb(229 223 92);
  color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.usercard4 {
  background-color: #7f94d9;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.usercard5 {
  background-color: #e55353;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.heading123 {
  font-weight: 100;
  font-size: 17px;
  font-weight: 600;
  text-decoration: underline;

  /* margin-left: 10px; */
}
.countp {
  font-weight: bold;
  font-size: 30px;
  margin-left: 10px;
  display: flex;
  width: 250px;
  text-decoration: underline;
}
.ant-popover-title {
  width: 230px;
}

/* admin profile  */
.createslot {
  display: flex;
  justify-content: center;
  width: 100vw;
  /* background-color: grey; */
  /* height: 50vh; */
  margin-top: 30px;
}
.cardfirst {
  display: flex;
  width: 30vw;
  /* background-color: red; */
  margin-left: 20px;
  height: 42vh;
}
.cardsecond {
  display: flex;
  width: 50vw;
  justify-content: space-evenly;
  border-radius: 20px;
  /* background-color: green; */
  /* height: 50vh; */
  flex-direction: column;
  /* overflow-y: scroll; */
}
.twocards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(241, 234, 234);
  width: 55vw;
  /* overflow-y: scroll; */
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.adminRole td {
  background-color: red;
  color: red;
}
.twocardstable {
  background-color: #fff3db;
  border: none;
  height: 60vh;
  overflow-y: scroll;
}
.twocardstable th {
  background-color: #fff3db !important;
}
.student-enrolled {
  /* width: 550px; */
  height: 100px;
  background-color: #f1eaea;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 27vw;
  /* background-color: rgb(231, 214, 214); */
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: rgb(0, 0, 0);
  padding: 10px;
  margin-top: 10px;
}
.show-count {
  display: "flex";
  justify-content: "space-between";
  align-items: "center";
  width: "20vw";
  padding: "10px";
}
.student-cards {
  background-color: #dad5d5;
  height: auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

/* canvas{
  width: 700px !important;
  height: 350px !important;
} */
/* EnrolledUserProgressBar.css */
/* EnrolledUserProgressBar.css */
/* EnrolledUserProgressBar.css */
.progress-bar-container {
  position: absolute;
  width: 25vw;
  height: 23vw;
  margin-bottom: 30px;
}

/* Arrow styling using ::before pseudo-element */
.progress-bar-container .chartjs-render-monitor::before {
  content: "\2193"; /* Unicode arrow character (downwards arrow) */
  position: absolute;
  /* top: 80%;
  left: 10%; */
  transform: translateX(-50%);
}

/* Style for the online box */
.dash-chart {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-width: 99vw;
  height: 500px;
}

@media only screen and (max-width: 800px) {
  .dash-chart {
    flex-direction: column; /* Change to column when screen size is reduced */
  }

  .session2024,
  .user-chart {
    width: 80vw; /* Adjust width as needed */
    margin-bottom: 20px; /* Add margin between stacked elements */
  }
}

.session2024,
.user-chart {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40vw;
  height: 400px;
  border-radius: 20px;
  background-color: #f3f1f1;
}
.admin-chart {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.admin-chart table {
  /* height: 100px; */
  width: 400px;
  overflow-y: scroll;
}

/* login page  */

.body-new {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f0f0f0;
  margin-top: -30px;
  /* padding-top: 20px; */
}

.wrapper-new {
  min-height: 100px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
  padding: 30px;
  width: 50vw;
}
.logo-login11 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-field-new {
  width: 100%;
  height: 45px;
  border: none;
  padding: 10px;
  background-color: #eeeeee;
  color: rgb(0, 0, 0);
  outline: none;
  font-size: 15px;
  margin-bottom: 20px;
  transition: 0.5s;
  border-radius: 5px;
  font-weight: bold;
  letter-spacing: 2px;
}

.heading-new {
  color: #3b3663;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.heading-new p {
  color: #aaa8bb;
}

.heading-new i {
  font-size: 30px;
  color: #4d61fc;
}

.button-new {
  width: 100%;
  height: 45px;
  border: none;
  color: #ffffff;
  /* background-color: #31285C; */
  background: linear-gradient(to right, #c02425, #f0dd93);
  border-radius: 5px;
  font-size: 17px;
  font-weight: 500;
  transition: 0.3s;
  font-weight: bold;
  letter-spacing: 3px;
}

.button-new:hover {
  background-color: #31283b;
}

.row-new {
  min-width: 5px;
  min-height: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 15px;
}

.custom-checkbox {
  width: 17px;
  height: 17px;
  border-radius: 5px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  margin-right: 5px;
}

input[type="checkbox"]:checked ~ .custom-checkbox {
  background-color: #31285c;
}

input[type="checkbox"]:checked ~ .custom-checkbox::before {
  font-family: "Font Awesome 5 Free";
  content: "\f00c";
  display: inline-block;
  font-weight: 900;
  color: #ffffff;
}

footer {
  text-align: center;
  margin: 20px;
}

.logo-login {
  animation: 2s lfnt;
}
@keyframes lfnt {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
/* title logo  */
/* Define a class for the container element */

/* new navbar  */
.new-navbar {
  width: 100vw;
  height: 13vh;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 253, 253);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.heading-navbar {
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: "Courier New", Courier, monospace;
}
.ant-drawer .ant-drawer-close {
  width: 20px;
  display: none;
}